import loadable from "@loadable/component"
import { Link, graphql } from "gatsby"
import React,{useState, useEffect} from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import { PageLinks } from "../common/site/page-static-links"
import AccordianValuation from "../components/AccordianValuation/AccordianValuation"
import PageWithIcon from "../components/PageWithIcon/PageWithIcon"
import TwoColBlock from "../components/TwoColBlock/TwoColBlock"
import ValNewsSlider from "../components/ValNewsSlider/ValNewsSlider"
import Layout from "../components/layout"
import SEO from "../components/seo"
import reviewimg from "../images/google_review-w.svg"
import GGFXImage from "../modules/GGFXImage"

import qs from "qs"
import logoBlack from "../images/logo-white.svg"
import { removeDataAttributes } from "../components/Common/utils"
import SecondaryPageBanner from "../components/SecondaryPageBanner/SecondaryPageBanner"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const ValuationCard = loadable(() =>
  import("../components/ValuationCard/ValuationCard")
)

const ValuationLanding = ({ data, pageContext }, props) => {
  // const PageData = data?.strapiPage
  const [PageData, setPageData] = useState(data?.strapiPage)
  const [loading,setLoading] = useState(true)
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)

  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false)

  const blogs = data?.allStrapiBlog?.edges
  // console.log(PageData)

  let popUpData = ""

  if (PageData?.add_page_modules?.length > 0) {
    const results = PageData?.add_page_modules?.filter(
      item => item.__typename === "STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT"
    )
    if (results?.length > 0) {
      popUpData = results[0]
    }
  }

  const query = qs.stringify(
    {
      populate: {
        ggfx_results:"*",
        choose_menu: "*",
        select_popular_search:"*",
        seo: "*",
        add_page_modules: {
          on: {
            'page-modules.image-content': {
              populate: {
                left_right_block: {
                  populate:{
                  image:"*",
                  menu:{
                    populate:{
                      parent:"*"
                    }
                  }
                }
              }
              },
            },
            "page-modules.valuation-module":{
              populate:{
                image: "*",
                add_details:{
                  populate:{
                    cta_link:{
                      populate:{
                        parent:"*"
                      }
                    },
                    image:"*"
                  }
                }
              }
            },
            
           
            "page-modules.accordion":{
              populate:{
                add:{
                  populate:"*"
                }
              }
            },
            "page-modules.icon-content":{
              populate:{
                icon_module:{
                  populate:{
                    image:"*"
                  }
                }
              }
            },
            "page-modules.secondary-banner":{
              populate:{
                image:"*"
              }
            },
            "page-modules.custom-modules":{
              populate:{
                select_module:"*",
                add_cta_item:{
                  populate:{
                    link:"*"
                  }
                }
              }
            }
          },
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/pages/${urlParams.get('strapi_id')}?${query}`

    // const apiUrl=process.env.GATSBY_STRAPI_SRC+'/api/events/'+urlParams.get('strapi_id')+'?populate[0]=modules&populate[1]=banner.cta_1_link&populate[2]=banner.cta_2_link&populate[3]=modules.icon_content&populate[4]=modules.icon_content.icon_module.image&populate[5]=form_content&populate[6]=ggfx_results&populate[12]=banner.image'
    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

    if(isPreview){
    setIsPreviewEnabled(true)
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const getData = await response.json();
        const previewData=removeDataAttributes(getData)
        setLoading(false)
        setEnablePreview(false)

        let myPrevData;
        if(PageData){
          myPrevData = PageData
        } else {
          myPrevData = {};
          myPrevData.banner.banner_image = {}
          myPrevData.add_page_modules=[]
          myPrevData.id = urlParams.get('strapi_id')
        }

        if(previewData){
          
          myPrevData.imagetransforms = previewData?.imagetransforms
          myPrevData.title = previewData?.title
          myPrevData.layout = previewData?.layout
          myPrevData.pdf = previewData?.pdf
          myPrevData.select_popular_search = previewData.select_popular_search
          myPrevData.slug = previewData?.slug
          myPrevData.mobile_image = previewData?.mobile_image
          myPrevData.choose_menu = previewData?.choose_menu

          myPrevData.ggfx_results = previewData?.ggfx_results
          myPrevData.add_page_modules=previewData?.add_page_modules
          setPageData(PageData => ({ ...PageData, myPrevData }));
          sessionStorage.setItem("previewMeta",JSON.stringify(previewData?.seo))
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();
    }
    else{
      setLoading(false)
      setIsPreviewEnabled(false)
      sessionStorage.removeItem("previewMeta")
    }

  },[])

  if(loading){
    return (
      <section className={"loader-wrapper"}>
        <div id="loader-wrapper">
          <div id="loader" class="new-loader">
            <img
              className="logo-white loader-logo"
              src={logoBlack}
              alt="logo"
            />
          </div>
        </div>
      </section>
    )
  }

  if(enablePreview){
    return null
  }


  return (
    <Layout
      popUpData={popUpData}
      dark={true}
      popularSearch={PageData?.select_popular_search?.title}
    >
      <div className="layout-padding-top"></div>
      {PageData?.add_page_modules?.length > 0 &&
        PageData.add_page_modules?.map((module, index) => {
          var imagename = "page.valuation_module.image"

          let processedImages = JSON.stringify({})
          if (PageData?.imagetransforms) {
            processedImages =
              PageData?.imagetransforms?.valuation_module_Transforms
          }

          return (
            <>
              {(module.strapi_component === "page-modules.valuation-module"  ||
              module?.__component === "page-modules.valuation-module")
              && (
                <div className="valuation-wrapper">
                   <GGFXImage
                    ImageSrc={module?.image}
                    altText={`${
                      module?.image?.alternativeText
                        ? module?.image?.alternativeText
                        : module.title
                        ? module.title
                        : module.title
                    } banner`}
                    imagetransforms={PageData?.ggfx_results}
                    renderer="bgImg"
                    imagename={imagename}
                    strapiID={PageData?.strapi_id}
                    className="valuation-banner"
                />
                <div className="overlay-bg-valaution"></div>
                  <ScrollAnimation
                    animateIn="animate__slideInUp"
                    animateOnce
                    delay={100}
                    offset={100}
                    className="val_temp-wrapper"
                  >
                    <Container>
                      <Row className="d-flex justify-content-center">
                        <Col xl={8}>
                          <h1>{module.title}</h1>
                          {((module.content?.data?.content || isPreviewEnabled && module.content)) && (
                            <ContentModule
                              Content={module.content?.data?.content || module.content}
                            />
                          )}
                          <div className="valuation-card-wrapper-main">
                            {module.add_details &&
                              module.add_details.map((item, index) => {
                                return (
                                  // <ScrollAnimation animateIn="animate__slideInUp" delay={index * 100} animateOnce offset={50}>
                                  <ValuationCard
                                    index={index}
                                    isPreviewEnabled={isPreviewEnabled} 
                                    {...item}
                                    id={PageData.strapi_id}
                                    imagetransforms={
                                      PageData?.imagetransforms
                                        ?.tile_section_image_Transforms
                                    }
                                    // img={ValuationImg_1}
                                    text={item.title}
                                  />
                                  // </ScrollAnimation>
                                )
                              })}
                          </div>
                          <div className="review-bk">
                            <div className="review-img">
                              <Link to={`/${PageLinks?.reviews}/`}>
                                <img src={reviewimg} alt="reviews icon" />
                              </Link>
                            </div>
                            {/* <div className="review-txt">
                              <b>4.7/5</b> from over{" "}
                              <Link to="">700+ reviews</Link>
                            </div> */}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </ScrollAnimation>
                </div>
              )}
              {(module?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_ACCORDION" ||
                module?.__component === "page-modules.accordion")
                && (
                <AccordianValuation
                  add_toggle={module?.add}
                  title={module?.title}
                  content={module?.content}
                  valuation={true}
                  isPreviewEnabled={isPreviewEnabled} 
                />
              )}
              {(module?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT" ||
                module?.__component ==="page-modules.image-content")
                && (
                <TwoColBlock
                  id={PageData.strapi_id}
                  imagetransforms={PageData?.imagetransforms}
                  {...module}
                  valuation
                  ggfx_results={PageData?.ggfx_results}
                  isPreviewEnabled={isPreviewEnabled} 
                />
              )}
              {(module?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_SECONDARY_BANNER" ||
              module?.__component === "page-modules.secondary-banner")
              && (
              <div>
                <SecondaryPageBanner isPropertyValuation data={module} pageData={PageData} />
              </div>
            )}
              {(module?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES" ||
                module?.__component === "page-modules.custom-modules")
                &&
                module?.select_module === "blog" && (
                  <ValNewsSlider isPreviewEnabled={isPreviewEnabled}  {...module} data={blogs} />
                )}
              {(module?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_ICON_CONTENT" ||
                module?.__component ==="page-modules.icon-content")
                &&
                (module?.layout === "page" ? (
                  <PageWithIcon
                    id={PageData?.strapi_id}
                    isPreviewEnabled={isPreviewEnabled} 
                    imagetransforms={
                      PageData?.imagetransforms?.icon_section_image_Transforms
                    }
                    {...module}
                  />
                ) : null)}
            </>
          )
        })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage

  //preview seo dynamic updates

  let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }

  const metaTitle = PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title

  const [preMetaTitle, setPreMetaTitle] = useState(metaTitle)


  useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof window !== "undefined" && isPreview) {
        try {
          const sessionMetaData = sessionStorage.getItem("previewMeta");
          if (sessionMetaData) {
            const parsedData = JSON.parse(sessionMetaData);
          if(parsedData?.metaTitle){
            setPreMetaTitle(parsedData?.metaTitle)
          }
        }
      } catch (error) {
        console.error("Error parsing session storage data:", error);
      }
    }
  }, 4000);

  // Clear the timeout if the component unmounts
  return () => clearTimeout(timer);
}, [isPreview]);

useEffect(() => {
  if(isPreview){
  document.title =  `${preMetaTitle} haus & haus`;
  }
}, [preMetaTitle]);
//

  return (
    <SEO
      title={(isPreview && preMetaTitle) ? preMetaTitle : metaTitle}
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
      imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i/file_7035ecdafe.jpg?updated_at=2024-07-08T11:28:34.283Z"

    />
  )
}

export default ValuationLanding

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
          ...PlainContentFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_ICON_CONTENT {
          __typename
          ...IconFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_SECONDARY_BANNER {
          __typename
          id
          image {
            url
          }
          heading
          content {
            data {
              content
            }
          }
          description {
            data {
              description
            }
          }
          video_url
          title
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_VALUATION_MODULE {
          ...ValuationModuleFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT {
          __typename
          ...ImageContentFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES {
          __typename
          select_module
          title
          content {
            data {
              content
            }
          }
          add_cta_item {
            link {
              slug
              strapi_parent {
                slug
              }
            }
            title
            content {
              data {
                content
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          __typename
          id
          add {
            content {
              data {
                content
              }
            }
            title
          }
          title
          content {
            data {
              content
            }
          }
        }
      }
    }

    allStrapiBlog(
      filter: { publish: { eq: true } }
      sort: { fields: date, order: ASC }
    ) {
      edges {
        node {
          title
          slug
          video_url
          read_time
          date(formatString: "DD MMM, yy")
          strapi_id
          tile_image {
            url
          }
          ggfx_results {
            src_cftle
            transforms {
              url
              transform
              format
            }
          }
          imagetransforms {
            tile_image_Transforms
          }
        }
      }
    }
  }
`
